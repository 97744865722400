@tailwind base;
@tailwind components;
@tailwind utilities;

*:not(.focus-none) {
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: theme("colors.primary");
  transition-property: outline-offset;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}

*:not(.focus-none):focus {
  outline-offset: 6px;
}

.primary {
  @apply text-primary-dark;
}
.primary .colored {
  @apply text-primary;
}

.secondary {
  @apply text-secondary-dark;
}
.secondary .colored {
  @apply text-secondary;
}

.danger {
  @apply text-danger-dark;
}
.danger .colored {
  @apply text-danger;
}

.success {
  @apply text-success-dark;
}
.success .colored {
  @apply text-success;
}

.warning {
  @apply text-warning-dark;
}
.warning .colored {
  @apply text-warning;
}

.bg-default {
  @apply bg-white;
}
.bg-default-soft {
  @apply bg-grey-light;
}
.border-default {
  @apply border-white;
}
.border-default-soft {
  @apply border-grey-light;
}
.text-default {
  @apply text-white;
}
.text-default-soft {
  @apply text-grey-light;
}

.dark .primary {
  @apply text-primary-light;
}
.dark .secondary {
  @apply text-secondary-light;
}
.dark .danger {
  @apply text-danger-light;
}
.dark .success {
  @apply text-success-light;
}
.dark .warning {
  @apply text-warning-light;
}
.dark .bg-default {
  @apply bg-black;
}
.dark .bg-default-soft {
  @apply bg-grey-dark;
}
.dark .border-default {
  @apply border-black;
}
.dark .border-default-soft {
  @apply border-grey-dark;
}
.dark .text-default {
  @apply text-black;
}
.dark .text-default-soft {
  @apply text-grey-dark;
}
